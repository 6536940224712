import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { mailService, mailGlobalVariable } from './apps/mailbox/mail.service';
import { SharedModule } from './shared/shared.module';
import {APP_BASE_HREF, CommonModule, DatePipe} from '@angular/common';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoginComponent } from './authentication/login/login.component';
import { AlertComponent } from './components/alert/alert.component';
import { SuccessComponent } from './components/success/success.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {SplitterModule} from 'primeng/splitter';
import { CookieService } from 'ngx-cookie-service';
import { CookiesService } from './services/cookies.service';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { AuthInterceptorService } from './interceptors/auth.interceptor.service';
import { MenuModule } from 'primeng/menu';
import { EmailStatusComponent } from './components/email-status/email-status.component';
import {CuentaModule} from "./routes/cuenta/cuenta.module";
import {AppHeaderComponent} from "./layouts/full/header/header.component";
import {NotificationsComponent} from "./cp/notifications/notifications.component";
import {WebSocketAPI} from "./libraries/websocketapi";
import {WebSocketShareService} from "./libraries/websocketshare.service";
import {NgxPaginationModule} from "ngx-pagination";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {ToastAlertComponent} from "./components/toast-alert/toast-alert.component";
import { OrdenesServiciosComponent } from './cp/views/prefacturacion/create/ordenes-servicios/ordenes-servicios.component';
// import { InputComponent } from './components/input/input.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

const maskConfig: Partial<IConfig> = { validation: false, };

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        AppBlankComponent,
        AppSidebarComponent,
        AppBreadcrumbComponent,
        LoginComponent,
        AlertComponent,
        SuccessComponent,
        ConfirmComponent,
        EmailStatusComponent,
        AppHeaderComponent,
        NotificationsComponent,
        ToastAlertComponent,
        OrdenesServiciosComponent
    ], imports: [
        CommonModule,
        BrowserModule,
        MatProgressBarModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        PerfectScrollbarModule,
        SharedModule,
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule.forRoot(AppRoutes),
        MenuModule,
        SplitterModule,
        CuentaModule,
        NgxPaginationModule,
        NgxMaskModule.forRoot(maskConfig),
    ], exports: [
    DemoMaterialModule
  ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        { provide: APP_BASE_HREF, useValue : "" },
        { provide : HTTP_INTERCEPTORS, useClass : AuthInterceptorService, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS,
          useValue: {
            parse: {
              dateInput: 'L',
            },
            display: {
              dateInput: 'L',
              monthYearLabel: 'MMMM DD [DE] YYYY',
              dateA11yLabel: 'll',
              monthYearA11yLabel: 'MMMM DD [DE] YYYY',
            },
          },
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AuthInterceptorService,
        //     multi: true
        // },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }, mailService, mailGlobalVariable,
        CookieService, CookiesService,
      WebSocketAPI, WebSocketShareService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
