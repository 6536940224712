import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {Globals} from "../../../../../libraries/globals";
import {SuccessComponent} from "../../../../../components/success/success.component";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import * as moment from 'moment';
import {CotizacionesService} from "../../../../../services/cotizaciones.service";
import {OrdenesPedidosOrdenesServiciosGerenciamientoIndexComponent} from "../gerenciamiento/index/index.component";
import {FileViewerComponent} from "../../../../../components/file-viewer/file-viewer.component";

@Component({
  selector: 'app-ordenes-servicios-create',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.scss']
})
export class OrdenesServiciosFinalizarComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose: EventEmitter<OrdenesServiciosFinalizarComponent> = new EventEmitter<OrdenesServiciosFinalizarComponent>();
  @Output('onChange') onChange: EventEmitter<OrdenesServiciosFinalizarComponent> = new EventEmitter<OrdenesServiciosFinalizarComponent>();

  public fgOrdenServicioTiemposEjecucion: FormGroup = new FormGroup({
    programadaFecha : new FormControl({value: '', disabled: true}),
    programadaHora  : new FormControl({value: '', disabled: true}),

    salidaOrigenFecha : new FormControl('', Validators.required),
    salidaOrigenHora  : new FormControl('', Validators.required),
    salidaOrigenKms   : new FormControl(''),
    salidaOrigenHrs   : new FormControl(''),

    llegadaDestinoFecha : new FormControl('', Validators.required),
    llegadaDestinoHora  : new FormControl('', Validators.required),
    llegadaDestinoKms   : new FormControl(''),
    llegadaDestinoHrs   : new FormControl(''),

    salidaDestinoFecha : new FormControl(''),
    salidaDestinoHora  : new FormControl(''),
    salidaDestinoKms   : new FormControl(''),
    salidaDestinoHrs   : new FormControl(''),

    llegadaOrigenFecha : new FormControl(''),
    llegadaOrigenHora  : new FormControl(''),
    llegadaOrigenKms   : new FormControl(''),
    llegadaOrigenHrs   : new FormControl(''),
    observaciones      : new FormControl('')
  });

  public Globals                     = Globals;
  public objApplicationConfig        = ApplicationConfig;
  public objOrdenPedido              : any = {}
  public objOrdenServicio            : any = {}
  public objScene                    : any = ["CLIENTE"];
  public lstUnidadesMiembros         : Array<any> = [];
  public lstOrdenesServiciosMiembros : Array<any> = [];
  public folio                       : string = '';
  public isFinalize                  : boolean = false;

  public objLoader = {
    type     : 'loader',
    visible:  false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnOptionBar = {
    type     : 'button',
    disabled : false
  };

  public btnAccion = {
    type     : 'button',
    disabled : false
  };

  public btnAceptar = {
    type     : 'button',
    disabled : false
  };

  public btnCancelar = {
    type     : 'button',
    disabled : false
  };

  public formComponents: Array<any> = [
    this.fgOrdenServicioTiemposEjecucion,
    this.btnOptionBar,
    this.btnAccion,
    this.objChkBox,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objOrdenesServiciosService   : OrdenesServiciosService,
    private objCotizacionesService       : CotizacionesService,
    private objDialogRef                 : MatDialogRef<OrdenesServiciosFinalizarComponent>,
    private objMatDialog                 : MatDialog,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) {
  }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());
  }

  public init(): void {
    if (Globals.exist(this.data.folio)) this.folio = this.data.folio;
    this.objOrdenesServiciosService.ending(this.data.idOrdenServicio)
      .subscribe(objResponse => {
        this.objOrdenPedido = objResponse.result?.ordenServicio;
        this.lstUnidadesMiembros = objResponse.result?.miembros;

        let programadaFecha = moment(this.objOrdenPedido.programadaFechaIso, "DD-MM-YYYY");
        this.fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].setValue((programadaFecha.isValid()) ? programadaFecha : '');
        this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].setValue(this.objOrdenPedido.programadaHora);

        if (Globals.isArray(objResponse.result?.ordenesServiciosGerenciamientos)) {
          this.lstOrdenesServiciosMiembros = objResponse.result.ordenesServiciosGerenciamientos;
        }

        this.fnInitUnidadMedicion();
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
        console.log(error);
      });
  }

  public fnInitUnidadMedicion() : void {
    this.lstUnidadesMiembros.forEach( itemOrdenServicioMiembro => {
      if (Globals.exist(itemOrdenServicioMiembro?.isPrincipal) && itemOrdenServicioMiembro.isPrincipal && Globals.isStringNotEmpty( itemOrdenServicioMiembro?.salidaOrigenFechaNatural ) &&  Globals.isStringNotEmpty( itemOrdenServicioMiembro?.salidaOrigenHoraIso )) {
        this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].setValue(moment(itemOrdenServicioMiembro?.salidaOrigenFechaIso, "DD-MM-YYYY"));
        this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].setValue(itemOrdenServicioMiembro?.salidaOrigenHoraIso.substring(0, 5));
      }

      if (Globals.exist(itemOrdenServicioMiembro?.isPrincipal) && itemOrdenServicioMiembro.isPrincipal && Globals.isStringNotEmpty( itemOrdenServicioMiembro?.llegadaDestinoFechaIso ) && Globals.isStringNotEmpty( itemOrdenServicioMiembro?.llegadaDestinoHoraIso )) {
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].setValue(moment(itemOrdenServicioMiembro?.llegadaDestinoFechaIso, "DD-MM-YYYY"));
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].setValue(itemOrdenServicioMiembro?.llegadaDestinoHoraIso.substring(0, 5));
      }

      if (Globals.exist(itemOrdenServicioMiembro?.isPrincipal) && itemOrdenServicioMiembro.isPrincipal && Globals.exist( itemOrdenServicioMiembro?.hasUnidadOdometro ) && itemOrdenServicioMiembro.hasUnidadOdometro) {
        this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenKms'].setValue(itemOrdenServicioMiembro?.salidaOrigenOdometro);
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms'].setValue(itemOrdenServicioMiembro?.llegadaDestinoOdometro);
        this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms'].setValue(itemOrdenServicioMiembro?.salidaDestinoOdometro);
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms'].setValue(itemOrdenServicioMiembro?.llegadaOrigenOdometro);
      }

      if (Globals.exist(itemOrdenServicioMiembro?.isPrincipal) && itemOrdenServicioMiembro.isPrincipal && Globals.exist( itemOrdenServicioMiembro?.hasUnidadHorometro ) && itemOrdenServicioMiembro.hasUnidadHorometro) {
        this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHrs'].setValue( itemOrdenServicioMiembro?.salidaOrigenHorometro );
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs'].setValue( itemOrdenServicioMiembro?.llegadaDestinoHorometro );
        this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs'].setValue( itemOrdenServicioMiembro?.salidaDestinoHorometro );
        this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs'].setValue( itemOrdenServicioMiembro?.llegadaOrigenHorometro );
      }
    });
  }

  public btnCotizacionPdf_clickEvent(): void {
    if (Globals.exist(this.objOrdenPedido) && Globals.exist(this.objOrdenPedido?.idCotizacion)) {
      FormOptimizer.formDisable(this.formComponents);
      this.objCotizacionesService.documentPdf(this.objOrdenPedido.idCotizacion)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    }
  }

  public formOrdenServicioCreate_submitEvent(): void {
    (<any>Object).values(this.fgOrdenServicioTiemposEjecucion.controls).forEach((itemControl: FormControl) => itemControl.markAsTouched());

    if (this.fgOrdenServicioTiemposEjecucion.valid) {
      if (this.validateFinalizar()) {
        this.fnProcessCreate();
      } else {
        this.objMatDialog.open(AlertComponent, Globals.successConfig({titulo: 'Finalizar orden de servicio', mensaje: 'No se puede finalizar la orden de servicio ' + this.folio  + ', debe finalizar las actividades que aun se encuentran activas.', autoCloseDelay: 5000}, {maxWidth: '562px'}));
        this.objScene = ["GERENCIAMIENTOS"];
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de formulario', mensaje: 'Los datos de la operación no están completos, complete la información.'}));
      this.objScene = ["OPERACION"];
    }
  }

  public fnProcessCreate(): void {

    let fdOrdenServicioFinalize = new FormData();
    fdOrdenServicioFinalize.append("idOrdenServicio"         , FormOptimizer.formDataNumber(this.data.idOrdenServicio));
    fdOrdenServicioFinalize.append("salidaOrigenFecha"       , FormOptimizer.formDataDate(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha']));
    fdOrdenServicioFinalize.append("salidaOrigenHora"        , FormOptimizer.formDataTime(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora']));
    fdOrdenServicioFinalize.append("salidaOrigenOdometro"    , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenKms']));
    fdOrdenServicioFinalize.append("salidaOrigenHorometro"   , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHrs']));
    fdOrdenServicioFinalize.append("llegadaDestinoFecha"     , FormOptimizer.formDataDate(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha']));
    fdOrdenServicioFinalize.append("llegadaDestinoHora"      , FormOptimizer.formDataTime(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora']));
    fdOrdenServicioFinalize.append("llegadaDestinoOdometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms']));
    fdOrdenServicioFinalize.append("llegadaDestinoHorometro" , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs']));
    fdOrdenServicioFinalize.append("salidaDestinoFecha"      , FormOptimizer.formDataDate(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoFecha']));
    fdOrdenServicioFinalize.append("salidaDestinoHora"       , FormOptimizer.formDataTime(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHora']));
    fdOrdenServicioFinalize.append("salidaDestinoOdometro"   , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms']));
    fdOrdenServicioFinalize.append("salidaDestinoHorometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs']));
    fdOrdenServicioFinalize.append("llegadaOrigenFecha"      , FormOptimizer.formDataDate(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenFecha']));
    fdOrdenServicioFinalize.append("llegadaOrigenHora"       , FormOptimizer.formDataTime(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHora']));
    fdOrdenServicioFinalize.append("llegadaOrigenOdometro"   , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms']));
    fdOrdenServicioFinalize.append("llegadaOrigenHorometro"  , FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs']));
    fdOrdenServicioFinalize.append("observaciones"           , FormOptimizer.formDataString(this.fgOrdenServicioTiemposEjecucion.controls['observaciones']));

    this.lstUnidadesMiembros.forEach((itemUnidadMiembro, index) => {
      fdOrdenServicioFinalize.append("idOrdenServicioMiembro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.idOrdenServicioMiembro));
      fdOrdenServicioFinalize.append("llegadaDestinoOdometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaDestinoOdometro));
      fdOrdenServicioFinalize.append("llegadaDestinoHorometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaDestinoHorometro));
      fdOrdenServicioFinalize.append("salidaDestinoOdometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.salidaDestinoOdometro));
      fdOrdenServicioFinalize.append("salidaDestinoHorometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.salidaDestinoHorometro));
      fdOrdenServicioFinalize.append("llegadaOrigenOdometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaOrigenOdometro));
      fdOrdenServicioFinalize.append("llegadaOrigenHorometro" + index, FormOptimizer.formDataNumber(itemUnidadMiembro.llegadaOrigenHorometro));
    });

    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesServiciosService.finalize(fdOrdenServicioFinalize).subscribe(objResponse => {
      if (Globals.exist(objResponse.result?.isFinalize)) this.isFinalize = objResponse.result?.isFinalize;
      if (objResponse.action) {
        this.objOrdenServicio = objResponse.result.ordenServicio;
        this.change();
        this.close();
        this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
      } else
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
      FormOptimizer.formEnable(this.formComponents);
    }, error => {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
      FormOptimizer.formEnable(this.formComponents);
    });

  }

  public inputSalidaOrigenOdometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].salidaOrigenOdometro;
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenKms'].setValue(value);
    }
  }

  public boolValidationLlegadaDestinoOdometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaDestinoOdometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].llegadaDestinoOdometro;
      this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoKms'].setValue(value);
    }
  }

  public boolValidationSalidaDestinoOdometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.salidaDestinoOdometro)) {
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoOdometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputSalidaDestinoOdometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].salidaDestinoOdometro;
      this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoKms'].setValue(value);
    }
  }

  public boolValidationLlegadaOrigenOdometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaOrigenOdometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenOdometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoOdometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenOdometro) < Number(this.lstUnidadesMiembros[index]?.salidaDestinoOdometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaOrigenOdometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].llegadaOrigenOdometro;
      this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenKms'].setValue(value);
    }
  }

  public inputSalidaOrigenHorometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].salidaOrigenHorometro;
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHrs'].setValue(value);
    }
  }

  public boolValidationLlegadaDestinoHorometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaDestinoHorometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].llegadaDestinoHorometro;
      this.fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHrs'].setValue(value);
    }
  }

  public boolValidationSalidaDestinoHorometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.salidaDestinoHorometro)) {
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].salidaDestinoHorometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputSalidaDestinoHorometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].salidaDestinoHorometro;
      this.fgOrdenServicioTiemposEjecucion.controls['salidaDestinoHrs'].setValue(value);
    }
  }

  public boolValidationLlegadaOrigenHorometro(index: number): boolean {
    let boolReturn = false;

    if (Globals.isStringNotEmpty(this.lstUnidadesMiembros[index]?.llegadaOrigenHorometro)) {
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaOrigenHorometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.llegadaDestinoHorometro)) boolReturn = true;
      if (Number(this.lstUnidadesMiembros[index].llegadaOrigenHorometro) < Number(this.lstUnidadesMiembros[index]?.salidaDestinoHorometro)) boolReturn = true;
    }

    return boolReturn;
  }

  public inputLlegadaOrigenHorometro_changeEvent(event: KeyboardEvent, index: number): void {
    if (Globals.exist(this.lstUnidadesMiembros[index]?.isPrincipal) && this.lstUnidadesMiembros[index].isPrincipal) {
      let value = this.lstUnidadesMiembros[index].llegadaOrigenHorometro;
      this.fgOrdenServicioTiemposEjecucion.controls['llegadaOrigenHrs'].setValue(value);
    }
  }

  public btnOrdenServicioGerenciamiento_clickEvent(itemOrdenServicioMiembro : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesPedidosOrdenesServiciosGerenciamientoIndexComponent, {
      width        : '76%'  ,
      maxWidth     : '900px',
      height       : '100%' ,
      maxHeight    : '630px',
      data          : itemOrdenServicioMiembro,
      disableClose  : true
    });

    objDialog.componentInstance.onChange.subscribe( () => this.init());
  }

  public verPdf_clickEvent(itemOrdenServicio : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width     : '100%',
      maxWidth  : '100%',
      height    : '100%',
      maxHeight : '100%',
      data: {
        extension : 'pdf',
        fileName  : itemOrdenServicio?.ordenServicioFolio + '.pdf',
        url    : 'api/ordenes-servicios/pdf/' + itemOrdenServicio?.idOrdenServicio
      },
      backdropClass : 'backdropBackground',
      panelClass    : 'visualizarPanelClass',
      disableClose  : true
    });
  }

  public validateFinalizar() : boolean {
    return  !this.lstOrdenesServiciosMiembros.some(itemOrdenServicioGerenciamiento => itemOrdenServicioGerenciamiento?.idGerenciamientoEstatus < 4);
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public change(): void {
    this.onChange.emit(this);
  }
}
