import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordenes-servicios',
  templateUrl: './ordenes-servicios.component.html',
  styleUrls: ['./ordenes-servicios.component.scss']
})
export class OrdenesServiciosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
