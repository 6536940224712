<div class="mat-dialog-title">
  <div class="title">Gerenciamiento</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="closeGerenciamiento()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body mat-dialog-no-actions">
  <div class="bs-content-full-area">
    <div class="datatable-responsive">
      <div class="pestanias-content">
        <div class="pestanias-item" [style.flexBasis]="'calc(100% /' + lstUnidades.length + ')'" *ngFor="let itemUnidad of lstUnidades; let index = index">
          <mat-checkbox [disabled]="btnAceptar.disabled" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && (!Globals.exist(itemUnidad?.hasPuntosVerificacion) || (Globals.exist(itemUnidad?.hasPuntosVerificacion) && !itemUnidad.hasPuntosVerificacion))" [ngModel]="itemUnidad.checked" (ngModelChange)="mantenimientoTaller_checkEvent($event, index)"></mat-checkbox>
          <span class="material-icons icon-pestanias" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && (Globals.exist(itemUnidad?.hasPuntosVerificacion) && itemUnidad.hasPuntosVerificacion)">done</span>
          <span class="material-icons icon-pestanias-cancel" *ngIf="Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus == 4">block</span>
          <div class="pestanias-label" [class.active]="itemUnidad.active" (click)="selectScene_clickEvent(index)">{{itemUnidad?.unidadNumeroEconomico}}</div>
        </div>
      </div>
      <div class="bs-content" [class.item-opacity]="!lstUnidades[indexSelected]?.checked && !Globals.exist(lstUnidades[indexSelected]?.gerenciamientos)" style="overflow: auto; height: calc(100% - 30px);">
        <div class="main" [style.height.px]="220">
          <mat-card class="card-info-content no-border">
            <div class="card-body">
              <div class="card-content">
                <div class="card-text">
                  <mat-icon class="card-icon">description</mat-icon>
                  <div class="card-label">Orden pedido</div>
                  <div class="card-field">{{objOrdenPedido?.folio}}</div>
                </div>

                <div class="card-text" *ngIf="Globals.isStringNotEmpty(objOrdenPedido?.ticket)">
                  <mat-icon class="card-icon">confirmation_number</mat-icon>
                  <div class="card-label" matTooltip="Orden de pedido: ticket" [matTooltipDisabled]="!lstUnidades[indexSelected]?.checked">O.P. ticket</div>
                  <div class="card-field">{{objOrdenPedido.ticket}}</div>
                </div>

                <div class="card-text">
                  <mat-icon class="card-icon">description</mat-icon>
                  <div class="card-label">Orden servicio</div>
                  <div class="card-field">{{objOrdenServicio?.folio}}</div>
                </div>

                <div class="card-text" *ngIf="Globals.isStringNotEmpty(objOrdenServicio?.autoriza)">
                  <mat-icon class="card-icon">person</mat-icon>
                  <div class="card-label" matTooltip="Orden de servicio: autoriza" [matTooltipDisabled]="!lstUnidades[indexSelected]?.checked">O.S. autoriza</div>
                  <div class="card-field">{{objOrdenServicio?.autoriza}}</div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion) && !this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">location_pin</mat-icon>
                  <div class="card-label">Tipo de gerenciamiento</div>
                  <div class="card-field" style="flex: 0 0 160px">{{lstUnidades[indexSelected]?.gerenciamientoTipo}}</div>
                </div>
                <div class="card-inputs select" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">location_pin</mat-icon>
                  <div class="card-label">Tipo de gerenciamiento</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.idGerenciamientoTipo)">
                    <select class="table-select" [(ngModel)]="lstUnidades[indexSelected].idGerenciamientoTipo" [style.width.px]="140" [disabled]="!lstUnidades[indexSelected]?.checked" (change)="selectGerenciamiento_changeEvent()" [class.required]="!lstUnidades[indexSelected]?.gerenciamientosTipoValido">
                      <option *ngFor="let itemGerenciamientoTipo of lstGerenciamientosTipos" [value]="itemGerenciamientoTipo.idGerenciamientoTipo">{{itemGerenciamientoTipo.gerenciamientoTipo}}</option>
                    </select>
                  </div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion) && !this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">map</mat-icon>
                  <div class="card-label">Viaje necesario</div>
                  <div class="badge" [class]="lstUnidades[indexSelected]?.isViajeNecesario? 'success' : 'danger'">{{lstUnidades[indexSelected]?.isViajeNecesario?'Sí':'No'}}</div>
                </div>
                <div class="card-inputs" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">map</mat-icon>
                  <div class="card-label">Viaje necesario</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.isViajeNecesario)">
                    <mat-checkbox [(ngModel)]="lstUnidades[indexSelected].isViajeNecesario" [disabled]="!lstUnidades[indexSelected]?.checked" (change)="chkGerenciamiento_changeEvent()"></mat-checkbox>
                  </div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion) && !this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">groups</mat-icon>
                  <div class="card-label">Reunion previa al viaje</div>
                  <div class="badge" [class]="lstUnidades[indexSelected]?.hasReunionPrevia? 'success' : 'danger'">{{lstUnidades[indexSelected]?.hasReunionPrevia?'Sí':'No'}}</div>
                </div>
                <div class="card-inputs" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">groups</mat-icon>
                  <div class="card-label">Reunion previa al viaje</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.hasReunionPrevia)">
                    <mat-checkbox [(ngModel)]="lstUnidades[indexSelected].hasReunionPrevia" [disabled]="!lstUnidades[indexSelected]?.checked" (change)="chkGerenciamiento_changeEvent()"></mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="card-text">
                  <mat-icon class="card-icon">local_shipping</mat-icon>
                  <div class="card-label">Unidad</div>
                  <div class="card-field">{{lstUnidades[indexSelected]?.unidadNumeroEconomico}}</div>
                </div>

                <div class="card-text">
                  <mat-icon class="card-icon">assignment</mat-icon>
                  <div class="card-label">Tipo de unidad</div>
                  <div class="card-field">{{lstUnidades[indexSelected]?.unidadTipo}}</div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion)">
                  <mat-icon class="card-icon">person</mat-icon>
                  <div class="card-label">Autoriza</div>
                  <div class="card-field">{{lstUnidades[indexSelected]?.autoriza}}</div>
                </div>
                <div class="card-inputs select" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion))">
                  <mat-icon class="card-icon">person</mat-icon>
                  <div class="card-label">Autoriza</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.autoriza)">
                    <input type="text" class="table-input" matInput [(ngModel)]="lstUnidades[indexSelected].autoriza" [disabled]="!lstUnidades[indexSelected]?.checked" [style.width.%]="100" maxlength="511">
                  </div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion)">
                  <mat-icon class="card-icon">person</mat-icon>
                  <div class="card-label">Autoriza salida</div>
                  <div class="card-field">{{lstUnidades[indexSelected]?.autorizaSalida}}</div>
                </div>
                <div class="card-inputs select" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion))">
                  <mat-icon class="card-icon">person</mat-icon>
                  <div class="card-label">Autoriza salida</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.autorizaSalida)">
                    <input type="text" class="table-input" matInput [(ngModel)]="lstUnidades[indexSelected].autorizaSalida" [disabled]="!lstUnidades[indexSelected]?.checked" [style.width.%]="100" maxlength="511">
                  </div>
                </div>

                <div class="card-text" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion)">
                  <mat-icon class="card-icon">confirmation_number</mat-icon>
                  <div class="card-label">Ticket</div>
                  <div class="card-field">{{lstUnidades[indexSelected]?.ticket}}</div>
                </div>
                <div class="card-inputs select" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion))">
                  <mat-icon class="card-icon">confirmation_number</mat-icon>
                  <div class="card-label">Ticket</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.ticket)">
                    <input type="text" class="table-input" matInput [(ngModel)]="lstUnidades[indexSelected].ticket" [disabled]="!lstUnidades[indexSelected]?.checked" [style.width.%]="100" maxlength="511">
                  </div>
                </div>

                <div class="card-text" *ngIf="isGerenciamientosPSL && (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion) && !this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">inventory</mat-icon>
                  <div class="card-label">PSL Segmento</div>
                  <div class="card-field" style="flex: 0 0 160px">{{lstUnidades[indexSelected]?.gerenciamientoPSLSegmento}}</div>
                </div>

                <div class="card-inputs select" *ngIf="isGerenciamientosPSL && (!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <mat-icon class="card-icon">inventory</mat-icon>
                  <div class="card-label">PSL Segmento</div>
                  <div class="col-input" *ngIf="Globals.exist(lstUnidades[indexSelected]?.idGerenciamientoPSLSegmento) && !Globals.exist(lstUnidades[indexSelected]?.idOrdenServicioEstatus)">
                    <select class="table-select" [(ngModel)]="lstUnidades[indexSelected].idGerenciamientoPSLSegmento" [style.width.%]="100" [disabled]="!lstUnidades[indexSelected]?.checked" (change)="selectGerenciamiento_changeEvent()">
                      <option *ngFor="let itemGerenciamientoPSLSegmento of lstGerenciamientosPSLSegmentos" [value]="itemGerenciamientoPSLSegmento.idGerenciamientoPSLSegmento">{{itemGerenciamientoPSLSegmento.nombre}}</option>
                    </select>
                  </div>
                </div>

                <div class="card-text" *ngFor="let itemGerenciamiento of lstUnidades[indexSelected]?.gerenciamientos; let index = index;">
                  <mat-icon class="card-icon">description</mat-icon>
                  <div class="card-label">{{"Eval. riesgos (" + (itemGerenciamiento?.trayectoria | lowercase) + ")"}}</div>
                  <div class="card-field" style="width: calc(100% + 100px);">{{itemGerenciamiento.folio}}</div>
                  <div class="card-field">
                    <button class="add-usuario" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && !Globals.exist(itemGerenciamiento?.idGerenciamientoEvaluacionRiesgo)" [disabled]="Globals.exist(itemGerenciamiento?.enable) && !itemGerenciamiento.enable" matTooltip="Generar" (click)="geremciamientoManage_dialogEvent(itemGerenciamiento)">
                      <span class="material-icons">insert_drive_file</span>
                    </button>
                    <button class="add-usuario" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && Globals.exist(itemGerenciamiento?.idGerenciamientoEvaluacionRiesgo) && this.objPrivilegios?.GERENCIAMIENTOS_UPDATE" [disabled]="!this.objPrivilegios?.GERENCIAMIENTOS_UPDATE" matTooltip="Modificar" (click)="geremciamientoEdit_dialogEvent(itemGerenciamiento)">
                      <span class="material-icons">edit_document</span>
                    </button>
                    <button class="add-usuario" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && Globals.exist(itemGerenciamiento?.idGerenciamientoEvaluacionRiesgo)" matTooltip="Mostrar PDF" (click)="verPdf_clickEvent(itemGerenciamiento)">
                      <i class="pi pi-file-pdf" ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="punto-verificacion-contain" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)">
          <div class="punto-verificacion-header">
            <div class="pv-content">
              <div class="pv-labels">
                <span class="pv-title">Planificación de horarios</span>
                <span class="pv-subtitle">Estimación de tiempos para los puntos de revisión</span>
              </div>
            </div>
            <div class="pv-content">
              <div class="pv-labels" [style.width.px]="183">
                <span class="pv-title">intervalos de reporte</span>
                <span class="pv-subtitle">Solo para puntos intermedios</span>
              </div>
              <div class="pv-inputs">
                <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected]?.checkPoint)" [style.width.px]="100">
                  <input type="text"
                         [style.width.%]="100"
                         class="table-input text-center"
                         mask="Hh:m0"
                         [leadZeroDateTime]="true"
                         [(ngModel)]="lstUnidades[indexSelected].checkPoint"
                         matInput
                         maxlength="5"
                         (keyup)="inputCheckpointTime_keyUpEvent($event)"
                         [disabled]="!lstUnidades[indexSelected]?.checked"
                         autocomplete="off">
                  <span class="suffixSpan">Hrs.</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="datatable-responsive">
              <table class="datatable">
                <thead>
                <tr>
                  <th colspan="3">Puntos de verificación</th>
                  <th [style.width.px]="130">Fecha</th>
                  <th [style.width.px]="105">Hora</th>
                  <th [style.width.px]="150">Odometro</th>
                  <th [style.width.px]="150">Horometro</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td [style.width.px]="35"></td>
                  <td [style.width.px]="60" class="text-right">Origen</td>
                  <td> {{objOrdenServicio?.direccionOrigen}} </td>
                  <td>
                    <div class="table-input-calendar" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaOrigenFecha)">
                      <input class="text-center" matInput [(ngModel)]="lstUnidades[indexSelected].salidaOrigenFecha" [disabled]="!lstUnidades[indexSelected]?.checked" [matDatepicker]="matDateProgramada" [readonly]="true" (click)="matDateProgramada.open()" (dateChange)="calcCheckPoint()">
                      <mat-datepicker #matDateProgramada></mat-datepicker>
                      <button mat-raised-button (click)="matDateProgramada.open()"><span class="material-icons">today</span></button>
                    </div>
                  </td>
                  <td>
                    <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaOrigenHora)">
                      <input type="text"
                             [style.width.px]="95"
                             class="table-input text-center"
                             mask="Hh:m0"
                             [leadZeroDateTime]="true"
                             [(ngModel)]="lstUnidades[indexSelected].salidaOrigenHora"
                             matInput
                             maxlength="5"
                             (keyup)="inputSalidaOrigenTime_keyUpEvent($event)"
                             [disabled]="!lstUnidades[indexSelected]?.checked"
                             autocomplete="off">
                      <span class="suffixSpan">Hrs.</span>
                    </div>
                  </td>
                  <td>
                    <div class="badge label-badge" *ngIf="!Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && !lstUnidades[indexSelected].hasUnidadOdometro)" matTooltip="No aplica">N/A</div>
                    <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaOrigenOdometro) && Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && lstUnidades[indexSelected].hasUnidadOdometro">
                      <input type="text"
                             [style.width.px]="140"
                             class="table-input text-right"
                             mask="separator.2"
                             thousandSeparator=","
                             separatorLimit="1000000000"
                             [(ngModel)]="lstUnidades[indexSelected].salidaOrigenOdometro"
                             [disabled]="!lstUnidades[indexSelected]?.checked"
                             matInput
                             autocomplete="off">
                      <span class="suffixSpan">Kms.</span>
                    </div>
                  </td>
                  <td>
                    <div class="badge label-badge" *ngIf="!Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && !lstUnidades[indexSelected].hasUnidadHorometro)" matTooltip="No aplica">N/A</div>
                    <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaOrigenHorometro) && Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && lstUnidades[indexSelected].hasUnidadHorometro">
                      <input type="text"
                             [style.width.px]="140"
                             class="table-input text-right"
                             mask="separator.2"
                             thousandSeparator=","
                             separatorLimit="1000000000"
                             [(ngModel)]="lstUnidades[indexSelected].salidaOrigenHorometro"
                             [disabled]="!lstUnidades[indexSelected]?.checked"
                             matInput
                             autocomplete="off">
                      <span class="suffixSpan">Hrs.</span>
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let itemUbicacion of lstUnidades[indexSelected]?.puntosVerificaciones; let index = index;">
                  <td [style.width.px]="35" class="text-center">
                    <button class="datatable-button" *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE" [disabled]="btnAccion.disabled">
                      <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnItemCotizacionCondicionEliminar_clickEvent(index)">clear</mat-icon>
                    </button>
                  </td>
                  <td [style.width.px]="60" class="text-right">{{index  + 1| numeroOrdinal}}</td>
                  <td *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion) && !this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">{{itemUbicacion.gerenciamientoPuntoVerificacion}}</td>
                  <td *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                    <input type="text" class="table-input" matInput [style.width.%]="100" [(ngModel)]="itemUbicacion.gerenciamientoPuntoVerificacion" maxlength="512" (change)="inputGerenciamiento_changeEvent()">
                  </td>
                  <td class="text-center">{{itemUbicacion.puntoVerificacionFecha}}</td>
                  <td class="text-center">{{itemUbicacion.puntoVerificacionHora}}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngIf="(!Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) || (Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && !lstUnidades[indexSelected].hasPuntosVerificacion)) || this.objPrivilegios?.GERENCIAMIENTOS_UPDATE">
                  <td [style.width.px]="35"></td>
                  <td [style.width.px]="60"></td>
                  <td> <button type="button" class="add-usuario" style="width: 80px" [disabled]="!lstUnidades[indexSelected]?.checked" (click)="btnAddCotizacionesCondiciones_clickEvent()">Agregar</button> </td>
                  <td colspan="4"></td>
                </tr>
                <tr>
                  <td [style.width.px]="35"></td>
                  <td [style.width.px]="60" class="text-right">Destino</td>
                  <td> {{objOrdenServicio?.direccionDestino}} </td>
                  <td>
                    <div class="table-input-calendar" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaDestinoFecha)">
                      <input class="text-center" matInput [(ngModel)]="lstUnidades[indexSelected].salidaDestinoFecha" [disabled]="!lstUnidades[indexSelected]?.checked" [matDatepicker]="matDateProgramada" [readonly]="true" (click)="matDateProgramada.open()" (dateChange)="inputSalidaDestinoDate_changeEvent()">
                      <mat-datepicker #matDateProgramada></mat-datepicker>
                      <button mat-raised-button (click)="matDateProgramada.open()"><span class="material-icons">today</span></button>
                    </div>
                  </td>
                  <td>
                    <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected]?.salidaDestinoHora)">
                      <input type="text"
                             [style.width.px]="95"
                             class="table-input text-center"
                             mask="Hh:m0"
                             [leadZeroDateTime]="true"
                             [(ngModel)]="lstUnidades[indexSelected].salidaDestinoHora"
                             matInput
                             maxlength="5"
                             (keyup)="inputSalidaDestinoTime_keyUpEvent($event)"
                             [disabled]="!lstUnidades[indexSelected]?.checked"
                             autocomplete="off">
                      <span class="suffixSpan">Hrs.</span>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="btn-gerenciamiento-content" *ngIf="!Globals.exist(lstUnidades[indexSelected]?.idGerenciamientoEstatus)">
          <button class="btn-gerenciar" type="button" [disabled]="btnAceptar.disabled || !lstUnidades[indexSelected]?.checked" [matTooltip]="'iniciar gerenciamiento para ' + lstUnidades[indexSelected]?.unidadNumeroEconomico" [matTooltipDisabled]="!lstUnidades[indexSelected]?.checked" (click)="formOrdenServicioCreate_submitEvent(lstUnidades[indexSelected])">{{'Generar ' + lstUnidades[indexSelected]?.unidadNumeroEconomico}}</button>
        </div>

        <div class="punto-verificacion-contain" *ngIf="(Globals.exist(lstUnidades[indexSelected]?.hasPuntosVerificacion) && lstUnidades[indexSelected].hasPuntosVerificacion)">
          <div class="cronograma-gerenciamiento-header">
            <div class="cg-content">
              <div class="cg-labels">
                <span class="cg-title">Cronograma de gerenciamiento</span>
                <span class="cg-subtitle">Linea de tiempo de actividades</span>
              </div>
            </div>
            <div class="cg-details">
              <div class="cg-labels">
                <span class="cg-title">Intervalos checkpoint</span>
                <span class="cg-subtitle">{{lstUnidades[indexSelected]?.checkPointNatural}}</span>
              </div>
              <div class="divider"></div>
              <div class="cg-labels">
                <span class="cg-title">Fecha compromiso</span>
                <span class="cg-subtitle">{{lstUnidades[indexSelected]?.compromisoFechaHoraNatural}}</span>
              </div>
              <div class="divider"></div>
              <div class="cg-labels">
                <span class="cg-title">Estatus</span>
                <span class="cg-subtitle">
                  <div class="badge" [class]="['gerenciamiento-estatus-' + lstUnidades[indexSelected]?.idGerenciamientoEstatus]" [style.width.px]="95">
                    {{lstUnidades[indexSelected]?.gerenciamientoEstatus}}
                  </div>
                </span>
              </div>
              <div class="divider"></div>
              <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Puntos de verificación" aria-label="Example icon-button with a menu">
                <mat-icon>place</mat-icon>
              </button>
              <mat-menu #menu="matMenu" [hasBackdrop]="false" class="simple-menu">
                <div class="mailbox" >
                  <div class="header">
                    <div class="drop-title" (click)="$event.stopPropagation()">Puntos de verificaciones</div>
                  </div>
                  <div class="body">
                    <div class="content-simple-menu" *ngIf="Globals.isArray(lstUnidades[indexSelected]?.puntosVerificaciones)">
                      <div class="item-simple-menu" *ngFor="let itemPuntoVerificacion of lstUnidades[indexSelected].puntosVerificaciones">
                        <span class="title">{{itemPuntoVerificacion?.gerenciamientoPuntoVerificacion}}</span>
                        <span class="subtitle">{{itemPuntoVerificacion?.puntoVerificacionFechaHoraNatural}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
          <div class="cronograma-gerenciamiento-body" *ngIf="Globals.exist(lstUnidades[indexSelected]?.gerenciamientosLog)">
            <div class="main">
              <mat-card class="card-info-content no-border">
                <div class="ficha-gerenciamiento-container">
                  <div class="ficha-gerenciamiento" *ngFor="let event of lstUnidades[indexSelected].gerenciamientosLog">
                    <div class="icon-content">
                      <div>
                        <span class="material-icons">{{(event?.idGerenciamientoLogTipo === 3)? 'pause' : (event?.idGerenciamientoLogTipo === 4)? 'hotel' : (event?.idGerenciamientoLogTipo === 6)? 'play_arrow' : 'place' }}</span>
                      </div>
                    </div>
                    <div class="registros-cronogramas-content">
                      <div class="registros-container">
                        <div class="ubicacion-content">
                          <div class="fg-title">
                            {{event?.gerenciamientoLogTipo + ": " + event?.puntoVerificacion}}
                          </div>
                          <div class="fg-subtitle text-ellipsis" style="max-width: 235px;" *ngIf="Globals.isStringNotEmpty(event?.comentarios)">
                            {{event?.comentarios}}
                          </div>
                          <div class="fg-subtitle">
                            {{event?.actualFechaHoraNatural}}
                          </div>
                        </div>

                        <div class="registro-content">
                          <div class="fg-title">
                            <i class="fas fa-tachometer-alt icon-select" style="font-size: 12px" ></i>&nbsp;Odometro
                          </div>
                          <div class="fg-subtitle" *ngIf="Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && lstUnidades[indexSelected].hasUnidadOdometro">
                            {{event?.actualOdometroNatural}}
                          </div>
                          <div class="fg-subtitle" *ngIf="!Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && !lstUnidades[indexSelected].hasUnidadOdometro)">
                            <div class="badge label-badge" matTooltip="No aplica">N/A</div>
                          </div>
                        </div>

                        <div class="registro-content">
                          <div class="fg-title">
                            <i class="fas fa-stopwatch icon-select"></i>&nbsp;Horometro
                          </div>
                          <div class="fg-subtitle" *ngIf="Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && lstUnidades[indexSelected].hasUnidadHorometro">
                            {{event?.actualHorometroNatural}}
                          </div>
                          <div class="fg-subtitle" *ngIf="!Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && !lstUnidades[indexSelected].hasUnidadHorometro)">
                            <div class="badge label-badge" matTooltip="No aplica">N/A</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ficha-gerenciamiento" *ngIf="(Globals.exist(objOrdenServicio?.idOrdenServicioEstatus) && objOrdenServicio.idOrdenServicioEstatus != 4) && (Globals.exist(lstUnidades[indexSelected]?.idGerenciamientoEstatus) && lstUnidades[indexSelected].idGerenciamientoEstatus != 4)">
                    <div class="icon-content">
                      <div>
                        <button class="menu-option" mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" class="itemMenu">
                          <button mat-menu-item *ngFor="let itemGerenciamientoLogTipo of lstGerenciamientosLogsTipos" (click)="selectGerenciamientoLogTipo_clickEvent(itemGerenciamientoLogTipo)">
                            <span>{{itemGerenciamientoLogTipo?.gerenciamientoLogTipo}}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                    <div class="registros-cronogramas-content">
                      <div class="registros-container" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                        <div class="ubicacion-content">
                          <div class="fg-title">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="fg-title" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <span class="material-icons" style="font-size: 14px">{{(lstUnidades[indexSelected].gerenciamientoLog.idGerenciamientoLogTipo === 3)? 'pause' : (lstUnidades[indexSelected].gerenciamientoLog.idGerenciamientoLogTipo === 4)? 'hotel' : (lstUnidades[indexSelected].gerenciamientoLog.idGerenciamientoLogTipo === 6)? 'play_arrow' : 'place' }}</span>&nbsp;{{(Globals.isStringNotEmpty(lstUnidades[indexSelected].gerenciamientoLog?.gerenciamientoLogTipo)? lstUnidades[indexSelected].gerenciamientoLog?.gerenciamientoLogTipo : '')}}
                            </div>
                          </div>
                          <div class="fg-inputs">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div style="width: 100%" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <input type="text" class="table-input" [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.puntoVerificacion" matInput [style.width.%]="100" autocomplete="off">
                            </div>
                          </div>
                        </div>
                        <div class="comentario-content">
                          <div class="fg-title">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="fg-title" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <span class="material-icons icon-select" style="font-size: 14px">description</span>&nbsp;Comentarios
                            </div>
                          </div>
                          <div class="fg-inputs">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div style="width: 100%" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <input type="text"
                                     class="table-input"
                                     [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.comentarios"
                                     matInput
                                     [style.width.%]="100"
                                     autocomplete="off">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="registros-container">
                        <div class="ubicacion-content">
                          <div class="fg-title">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="fg-title" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <span class="material-icons icon-select" style="font-size: 12px">calendar_today</span>&nbsp;Fecha
                            </div>
                          </div>
                          <div class="fg-inputs">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="table-input-calendar" [style.width.px]="130" [style.marginTop.px]="2" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <input class="text-center" matInput [matDatepicker]="matDateProgramada" [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.actualFechaIso" [readonly]="true" (click)="matDateProgramada.open()">
                              <mat-datepicker #matDateProgramada></mat-datepicker>
                              <button mat-raised-button (click)="matDateProgramada.open()"><span class="material-icons">today</span></button>
                            </div>

                            <div class="inputs-content" [style.width.px]="95" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <input type="text"
                                     [style.width.px]="95"
                                     class="table-input text-center"
                                     mask="Hh:m0"
                                     [leadZeroDateTime]="true"
                                     [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.actualHora"
                                     matInput
                                     maxlength="5"
                                     autocomplete="off">
                              <span class="suffixSpan">Hrs.</span>
                            </div>
                          </div>
                        </div>

                        <div class="registro-content">
                          <div class="fg-title">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="fg-title" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <i class="fas fa-tachometer-alt icon-select" style="font-size: 12px" ></i>&nbsp;Odometro
                            </div>
                          </div>
                          <div class="fg-inputs">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="badge label-badge" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo) && (!Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && !lstUnidades[indexSelected].hasUnidadOdometro))" matTooltip="No aplica">N/A</div>
                            <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo) && Globals.exist(lstUnidades[indexSelected]?.hasUnidadOdometro) && lstUnidades[indexSelected].hasUnidadOdometro">
                              <input type="text"
                                     [style.width.px]="135"
                                     class="table-input text-right"
                                     mask="separator.2"
                                     thousandSeparator=","
                                     separatorLimit="1000000000"
                                     [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.actualOdometro"
                                     matInput
                                     autocomplete="off">
                              <span class="suffixSpan">Kms.</span>
                            </div>
                          </div>
                        </div>

                        <div class="registro-content">
                          <div class="fg-title">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="fg-title" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                              <i class="fas fa-stopwatch icon-select"></i>&nbsp;Horometro
                            </div>
                          </div>
                          <div class="fg-inputs">
                            <div class="input-empty" *ngIf="!Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)"></div>
                            <div class="badge label-badge" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo) && (!Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) || (Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && !lstUnidades[indexSelected].hasUnidadHorometro))" matTooltip="No aplica">N/A</div>
                            <div class="inputs-content" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo) && Globals.exist(lstUnidades[indexSelected]?.hasUnidadHorometro) && lstUnidades[indexSelected].hasUnidadHorometro">
                              <input type="text"
                                     [style.width.px]="135"
                                     class="table-input text-right"
                                     mask="separator.2"
                                     thousandSeparator=","
                                     separatorLimit="1000000000"
                                     [(ngModel)]="lstUnidades[indexSelected].gerenciamientoLog.actualHorometro"
                                     matInput
                                     autocomplete="off">
                              <span class="suffixSpan">Hrs.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-content" *ngIf="Globals.exist(lstUnidades[indexSelected].gerenciamientoLog?.idGerenciamientoLogTipo)">
                      <div class="fg-title"></div>
                      <div class="fg-inputs">
                        <button type="button" class="add-usuario btn-cronograma" matTooltip="Guardar" [disabled]="btnAccion.disabled" (click)="aceptarGerenciamientoLogTipo_clickEvent()">
                          <span class="material-icons">done</span>
                        </button>
                        <button type="button" class="add-usuario btn-cronograma" matTooltip="Cancelar" [disabled]="btnAccion.disabled" (click)="cancelGerenciamientoLogTipo_clickEvent()">
                          <span class="material-icons">close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mat-dialog-loader mat-dialog-no-actions">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

